interface PrivateRouteProps {
  children: JSX.Element;
  isLogin?: boolean;
}

export const AuthPrivateRoute = ({ children }: PrivateRouteProps) => {
  if (window.opener === null) {
    window.location.href = "https://conectadoc.com.br/";
    return <></>;
  }
  return children;
};
