import { ReactNode } from "react";

import "animate.css";

import * as C from "./styles";

interface PageContainerProps {
  children: ReactNode;
}

const PageContainer = ({ children }: PageContainerProps) => {
  return (
    <div className="row g-0 m-0 min-vh-100">
      <main className="col-lg-5 col-12 align-self-center animate__animated animate__fadeIn">
        <div className="row justify-content-center m-0">
          <div className="col-11 col-md-6 col-lg-8 pt-3">{children}</div>
        </div>
      </main>
      <section className="col-lg-7 d-none d-lg-block">
        <C.Background className="h-100 animate__animated animate__fadeInRight animate__slow"></C.Background>
      </section>
    </div>
  );
};

export default PageContainer;
