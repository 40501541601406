import { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { useAuth } from "providers/auth";
import Button from "components/button";
import LogoHeader from "components/logoHeader";
import * as C from "./styles";
import PageContainer from "components/pageContainer";

const Logout = () => {
  const navigate = useNavigate();
  const { setIsLogged } = useAuth();

  const goToLogin = () => {
    setIsLogged(false);
    navigate("/login");
  };

  useEffect(() => {
    window.addEventListener(
      "message",
      (event) => {
        if (event.data.value === "logoutMsg" && event.data.message === true) {
          setIsLogged(event.data.message);
        }
      },
      false
    );

    window.opener.postMessage("openedReady", "*");

    localStorage.removeItem("@Conectadoc-auth:login");
  }, []);

  return (
    <PageContainer>
      <LogoHeader />
      <C.Header>
        <h1>Até breve!</h1>
        <p className="mb-5">Logout realizado com sucesso!</p>
      </C.Header>

      <div className="d-flex flex-column gap-3">
        <Button blueSchema onClick={goToLogin}>
          Fazer login novamente
        </Button>
        <Button onClick={() => window.close()}>Fechar</Button>
      </div>
    </PageContainer>
  );
};

export default Logout;
