import styled from "styled-components";

import bg from "assets/conectadoc_bg.jpg";

export const Main = styled.main`
  height: 100vh;
  display: block;
  position: relative;

  ::before {
    content: "";
    background: url(${bg}) no-repeat center;
    background-size: cover;
    opacity: 0.7;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }
`;
