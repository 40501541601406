import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";

import Input from "components/input";
import { cpfMask, validateCpf } from "utils/cpf";
import Button from "components/button";
import { api } from "services/api";
import { IdentificationData, PasswordRecoveryStepOneResult } from "types";
import {
  autoCloseValidationMessage,
  passwordRecoverySteps2and3,
} from "utils/popUp";
import ServiceByWpp from "../serviceByWpp";

const IdentificationForm = () => {
  const schema = yup.object().shape({
    emailOrCpf: yup
      .string()
      .required("*Campo obrigatório")
      .when("isEmail", {
        is: true,
        then: yup.string().email("*Formato incorreto"),
        otherwise: yup
          .string()
          .test("cpfValidator", "*CPF inválido", validateCpf),
      }),
    isEmail: yup.boolean(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IdentificationData>({ resolver: yupResolver(schema) });

  const onSubmit = async (data: IdentificationData) => {
    const { emailOrCpf } = data;

    Swal.showLoading();
    Swal.resetValidationMessage();

    try {
      const response = await api.get(`/Auth/recovery-contacts/${emailOrCpf}`);
      const resultStepOne: PasswordRecoveryStepOneResult = {
        request: { emailOrCpf },
        response: response.data,
      };
      Swal.hideLoading();

      // Step 2 - Recovery Code Request / Step3 - Code Validation
      passwordRecoverySteps2and3(emailOrCpf, <ServiceByWpp />, resultStepOne);
    } catch (error) {
      Swal.hideLoading();
      autoCloseValidationMessage(
        "CPF ou e-mail não encontrados no sistema. Verifique se seus dados estão corretos e tente novamente.",
        7000
      );
    }
  };

  const handleRecoveryPasswordInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;

    if (event.target.value.match("@")) {
      setValue("isEmail", true);
      setValue("emailOrCpf", inputValue);
    }
    if (!event.target.value.match(/([a-z])/gi)) {
      setValue("isEmail", false);
      setValue("emailOrCpf", cpfMask(inputValue));
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="px-2">
      <h5 className="mb-0">Esqueceu sua senha?</h5>
      <p>Sem problemas, iremos te auxiliar a recuperá-la.</p>
      <Input
        type="text"
        placeholder="CPF ou E-mail"
        register={register("emailOrCpf")}
        label="Informe seu CPF ou e-mail:"
        onChange={handleRecoveryPasswordInput}
        error={errors.emailOrCpf?.message}
        autoFocus
      />
      <Button type="submit" blueSchema className="btn-lg w-100 mt-3">
        Prosseguir
      </Button>
    </form>
  );
};

export default IdentificationForm;
