import { useState } from "react";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import * as I from "react-icons/bs";

import * as C from "./styles";
import Input from "components/input";
import Button from "components/button";
import { UserLoginData } from "types";
import { cpfMask, validateCpf } from "utils/cpf";
import { passwordRecoveryStep1 } from "utils/popUp";
import { useAuth } from "providers/auth";

const LoginForm = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoginSuccess, setIsLoginSuccess] = useState(false);
  const { login } = useAuth();

  const schema = yup.object().shape({
    email: yup
      .string()
      .required("*Campo obrigatório")
      .when("isEmail", {
        is: true,
        then: yup.string().email("*Formato incorreto"),
        otherwise: yup
          .string()
          .test("cpfValidator", "*CPF inválido", validateCpf),
      }),
    password: yup.string().required("*Campo obrigatório"),
    isEmail: yup.boolean(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<UserLoginData>({ resolver: yupResolver(schema) });

  const onSubmit = async (data: UserLoginData) => {
    login(data, setIsLoading, setIsLoginSuccess);
  };

  const handleLoginInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    if (inputValue.match("@")) {
      setValue("isEmail", true);
      setValue("email", inputValue);
    }
    if (!inputValue.match(/([a-z])/gi)) {
      setValue("isEmail", false);
      setValue("email", cpfMask(inputValue));
    }
  };

  const openPasswordRecoveryPopUp = () => {
    if (!isLoginSuccess) {
      // Step 1 - Identification
      passwordRecoveryStep1();
    }
  };

  return (
    <C.FormContainer
      onSubmit={handleSubmit(onSubmit)}
      disabled={isLoginSuccess}
    >
      <C.Header>
        <h1>Bem vindo!</h1>
        <p className="mb-5">Digite seus dados para acessar o sistema.</p>
      </C.Header>

      <Input
        icon={I.BsPerson}
        register={register("email")}
        error={errors?.email?.message}
        type="text"
        placeholder="CPF ou E-mail"
        onChange={handleLoginInput}
        autoFocus
        disabled={isLoginSuccess}
      />
      <Input
        icon={I.BsShieldLock}
        register={register("password")}
        error={errors?.password?.message}
        isInputPassword
        placeholder="Senha"
        disabled={isLoginSuccess}
      />

      <div className="row form-check d-flex ps-0 m-0 w-100">
        {/* <div className="col-md-7 p-0">
          <input
          type="checkbox"
            id="CheckDefault"
            className="form-check-input m-0 me-1"
          />
          
          <label htmlFor="">
            <small className="align-top">Manter conectado</small>
          </label>
        </div> */}
        <small
          className="p-0 mt-2 mb-2 text-end"
          onClick={openPasswordRecoveryPopUp}
        >
          Esqueceu a senha?
        </small>
      </div>

      {!isLoading ? (
        <Button
          type="submit"
          disabled={isLoginSuccess}
          blueSchema
          className="btn-lg w-100"
        >
          Entrar
        </Button>
      ) : (
        <C.Loading className="swal2-loader mt-5" />
      )}
    </C.FormContainer>
  );
};

export default LoginForm;
