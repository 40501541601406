import { Routes, Route } from "react-router-dom";

import Home from "pages/home";
import Login from "pages/login";
import { AuthPrivateRoute } from "./route";
import Logout from "pages/logout";

const pathnames = ["/", "/patient", "/secretary", "/doctor"];

const Router = () => {
  return (
    <Routes>
      <Route path="/sample" element={<Home />} />
      {pathnames.map((pathname, index) => (
        <Route
          key={index}
          path={pathname}
          element={
            <AuthPrivateRoute>
              <Login />
            </AuthPrivateRoute>
          }
        />
      ))}

      <Route
        path="/logout"
        element={
          <AuthPrivateRoute>
            <Logout />
          </AuthPrivateRoute>
        }
      />
    </Routes>
  );
};

export default Router;
