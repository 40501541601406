import styled from "styled-components";

interface FormContainerProps {
  disabled: boolean;
}

export const FormContainer = styled.form<FormContainerProps>`
  .form-check .form-check-input {
    height: 1.2rem;
    margin-top: 0.3em;
    width: 1.2rem;
  }

  .form-check > small {
    color: var(--bs-blue);
    text-decoration: underline;
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    opacity: ${(props) => props.disabled && "0.65"};

    :hover {
      text-decoration: ${(props) => (props.disabled ? "underline" : "unset")};
    }
  }
`;

export const Header = styled.header`
  h1 {
    color: var(--dark-blue);
    font-weight: 700;
  }
`;

export const Loading = styled.div`
  display: flex;
  margin: 0 auto;
`;
