export const cpfMask = (cpf: string) => {
  cpf = cpf.replace(/\D/g, "");

  let cpfSubstringsList = cpf.split("");
  let formattedCpf = cpfSubstringsList.slice(0, 11);
  const size = formattedCpf.length;

  if (size > 9) {
    return formattedCpf
      .join("")
      .replace(/^(\d{3})(\d{3})(\d{3})(\d{1,})$/g, "$1.$2.$3-$4");
  } else if (size > 6) {
    return formattedCpf
      .join("")
      .replace(/^(\d{3})(\d{3})(\d{1,})$/g, "$1.$2.$3");
  } else if (size > 3) {
    return formattedCpf.join("").replace(/^(\d{3})(\d{1,})$/g, "$1.$2");
  } else {
    return formattedCpf.join("");
  }
};

export const validateCpf = (cpf: any) => {
  if (!cpf) {
    return false;
  }

  let cpfStr: string;

  if (typeof cpf === "number") {
    cpfStr = cpf.toString();
  } else {
    cpfStr = cpf;
  }

  cpfStr = cpfStr.replace(/\D/g, "");

  let cpfNumbersList = cpfStr
    .split("")
    .map((cpfStrChar) => parseInt(cpfStrChar));

  if (cpfNumbersList.length !== 11) {
    return false;
  }

  // Check CPF with all digits the same
  if (cpfNumbersList.every((digit) => digit === cpfNumbersList[0])) {
    return false;
  }

  let digit1 = 0;
  // digit2 = 0

  // First Check Digit
  let sum = 0;
  let sumNumber = 2;

  for (const digit of cpfNumbersList.slice(0, 9).reverse()) {
    sum += digit * sumNumber;
    sumNumber++;
  }

  let rest = sum % 11;

  if (rest < 2) {
    if (cpfNumbersList[9] !== 0) {
      return false;
    } else {
      digit1 = 0;
    }
  } else {
    if (cpfNumbersList[9] !== 11 - rest) {
      return false;
    } else {
      digit1 = 11 - rest;
    }
  }

  // Second Check Digit
  sum = digit1 * 2;
  sumNumber = 3;

  for (const digit of cpfNumbersList.slice(0, 9).reverse()) {
    sum += digit * sumNumber;
    sumNumber++;
  }

  rest = sum % 11;

  if (rest < 2) {
    if (cpfNumbersList[10] !== 0) {
      return false;
    } // else digit2 = 0
  } else {
    if (cpfNumbersList[10] !== 11 - rest) {
      return false;
    } // else digit2 = 11 - rest
  }

  return true;
};
