import Button from "components/button";

interface LoginButtonProps {
  setToken: React.Dispatch<React.SetStateAction<string>>;
}

const LoginButton = ({ setToken }: LoginButtonProps) => {
  const receiveMessage = (event: any) => {
    if (event.data.value === "loginMsg") {
      const response = JSON.parse(event.data.message);

      const { token } = response.data;

      localStorage.setItem("@Conectadoc:token", JSON.stringify(token));

      setToken(token);
    }
  };

  const openLoginPopUp = () => {
    window.addEventListener("message", receiveMessage, false);

    window.open(
      "http://localhost:3000/", // TODO: adicionar URL definitiva login.conectadoc.com.br/...
      "Popup",
      "width=500,height=630,left=400,top=50"
    );
  };

  return (
    <Button blueSchema type="button" onClick={openLoginPopUp}>
      <strong>LOGIN</strong>
    </Button>
  );
};

export default LoginButton;
