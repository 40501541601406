import styled from "styled-components";

interface ContainerProps {
  isPasswordRegistration?: boolean;
  error?: string;
}

interface InputContainerProps {
  hasIcon?: boolean;
  hasError?: boolean;
  disabled?: boolean;
}

interface InputLabelProps {
  hasLabel?: boolean;
}

interface InputIconProps {
  hasError?: boolean;
  isInputPassword?: boolean;
  disabled?: boolean;
}

export const Container = styled.div<ContainerProps>`
  small {
    color: var(--bs-danger);
  }

  .input-error {
    height: ${(props) => !props.isPasswordRegistration && "1.3rem"};
  }
`;

export const InputContainer = styled.div<InputContainerProps>`
  border-radius: 0.3rem;
  border: 1px solid var(--bs-gray-400);
  border-color: ${(props) => props.hasError && "var(--bs-danger)"};

  input {
    padding-left: ${(props) => (props.hasIcon ? "2.5rem" : "1rem")};
    border: 0;
    border-radius: 0.3rem;
    color: var(--gray);
    cursor: ${(props) => props.disabled && "not-allowed"};
    opacity: ${(props) => props.disabled && "0.65 !important"};

    ::placeholder {
      color: var(--bs-gray-400);
    }

    :focus ~ div {
      color: var(--bs-primary);
    }
  }

  .form-control:focus {
    color: unset;
  }
`;

export const InputLabel = styled.label<InputLabelProps>`
  display: ${(props) => !props.hasLabel && "none"};
`;

export const InputIcon = styled.div<InputIconProps>`
  padding: 0 0.6rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: ${(props) => props.hasError && "var(--bs-danger)"};
  left: ${(props) => !props.isInputPassword && "0"};
  right: ${(props) => props.isInputPassword && "0"};
  cursor: ${(props) =>
    props.isInputPassword && !props.disabled ? "pointer" : "not-allowed"};
  opacity: ${(props) => props.disabled && "0.65"};
`;
