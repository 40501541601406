import styled from "styled-components";

export const Container = styled.div`
  .character {
    padding: 4px;
    border: 1px solid var(--bs-secondary);
  }
`;

export const SuccesMessage = styled.div`
  ::before {
    display: none;
  }

  .success-icon {
    width: 1.7rem;
    height: 1.7rem;
    margin: 0 0.625em;
  }
`;
