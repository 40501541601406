import Swal from "sweetalert2";
import withReactContent, { ReactElementOr } from "sweetalert2-react-content";
import "animate.css";

import CodeValidationForm from "pages/login/components/codeValidationForm";
import IdentificationForm from "pages/login/components/identificationForm";
import NewPasswordForm from "pages/login/components/newPasswordForm";
import { api } from "services/api";
import { PasswordRecoveryStepOneResult } from "types";

export const passwordRecoveryPopUp = withReactContent(
  Swal.mixin({
    progressSteps: ["1", "2", "3", "4"],
    showCloseButton: true,
    showConfirmButton: false,
    title: "Recuperar senha",
    customClass: {
      title: "text-uppercase",
      validationMessage: "animate__animated animate__fadeInDown",
    },
    showClass: {
      popup: "animate__animated animate__fadeInDown",
    },
    hideClass: {
      popup: "animate__animated animate__fadeOutDown",
    },
  })
);

export const passwordRecoveryStep1 = () => {
  return passwordRecoveryPopUp.fire({
    currentProgressStep: 0,
    html: <IdentificationForm />,
  });
};

export const passwordRecoverySteps2and3 = (
  emailOrCpf: string,
  footerComponent: ReactElementOr<"html">,
  resultStepOne: PasswordRecoveryStepOneResult
) => {
  return passwordRecoveryPopUp.fire({
    currentProgressStep: 1,
    showConfirmButton: true,
    text: "Em qual desses contatos você gostaria de receber o código de recuperação?",
    input: "radio",
    inputOptions: {
      Email: `${resultStepOne.response.email} (e-mail)`,
      WhatsApp: `${resultStepOne.response.phone} (WhatsApp)`,
      Sms: `${resultStepOne.response.phone} (SMS)`,
    },
    confirmButtonText: "Solicitar código de recuperação",
    showLoaderOnConfirm: true,
    customClass: {
      title: "text-uppercase",
      input: "flex-column gap-2 align-items-start",
      confirmButton: "btn btn-primary",
      footer: "flex-column mx-5",
    },
    buttonsStyling: false,
    footer: footerComponent,
    preConfirm: async (inputOption) => {
      if (!inputOption) {
        return autoCloseValidationMessage("Escolha uma das opções", 4000);
      }

      try {
        await api.post(`/Auth/recoveryBy${inputOption}`, resultStepOne.request);

        // Step 3 - Code Validation
        passwordRecoveryStep3(
          <CodeValidationForm
            emailOrCpf={emailOrCpf}
            inputOption={inputOption}
            resultStepOne={resultStepOne}
          />
        );
      } catch (error) {
        autoCloseValidationMessage(
          "Ocorreu um erro. Por favor, tente mais tarde",
          4000
        );
      }
    },
  });
};

const passwordRecoveryStep3 = (mainComponent: ReactElementOr<"html">) => {
  return passwordRecoveryPopUp.fire({
    currentProgressStep: 2,
    html: mainComponent,
  });
};

export const passwordRecoveryStep4 = (code: string, emailOrCpf: string) => {
  return passwordRecoveryPopUp.fire({
    currentProgressStep: 3,
    title: "Nova senha",
    html: <NewPasswordForm code={code} emailOrCpf={emailOrCpf} />,
  });
};

export const autoCloseValidationMessage = (
  message: string,
  duration: number
) => {
  Swal.showValidationMessage(message);
  setTimeout(() => {
    Swal.resetValidationMessage();
  }, duration);
};
