import styled from "styled-components";

interface ContainerProps {
  blueSchema?: boolean;
  disabled?: boolean;
}

export const Container = styled.button<ContainerProps>`
  pointer-events: auto !important;
  background-color: ${(props) =>
    props.blueSchema ? "var(--blue)" : "var(--bs-gray-300)"};
  color: ${(props) =>
    props.blueSchema ? "var(--bs-white)" : "var(--bs-dark)"};
  border-style: solid;
  border-color: ${(props) =>
    props.blueSchema ? "var(--blue)" : "var(--bs-gray-600)"};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  :hover {
    background-color: ${(props) =>
      props.blueSchema ? "#3950a2" : "var(--bs-gray-500)"};
    border-color: ${(props) =>
      props.blueSchema ? "#364b98" : "var(--bs-dark)"};
    color: ${(props) =>
      props.blueSchema ? "var(--bs-white)" : "var(--bs-dark)"};
  }
`;
