import { useState } from "react";

import { Container, Nav, Navbar } from "react-bootstrap";

import logo from "assets/logo.svg";
import * as C from "./style";
import LoginButton from "./components/loginButton";
import LogoutButton from "./components/logoutButton";

// Demonstração de home page apenas para verificar o funcionamento da autenticação.
const Home = () => {
  const [token, setToken] = useState("");

  return (
    <>
      <Navbar bg="info" className="border-bottom">
        <Container>
          <Navbar.Brand href="/">
            <img
              src={logo}
              className="bg-white p-1 img-fluid"
              alt="Logo Conectadoc"
            />
          </Navbar.Brand>

          <Nav className="gap-3">
            <LoginButton setToken={setToken} />
            <LogoutButton />
          </Nav>
        </Container>
      </Navbar>

      <C.Main className="container-fluid bg-image">
        <p>Token: {token} </p>
      </C.Main>
    </>
  );
};

export default Home;
