import { BsWhatsapp } from "react-icons/bs";

import { WppLink } from "./styles";

const wppNumber = 5541988776132;
const message = "Olá!%0aGostaria de ajuda para recuperar minha senha...";

const ServiceByWpp = () => {
  return (
    <>
      <span className="text-center">
        Caso ainda precise de ajuda, contate nossos atendentes clicando abaixo:
      </span>
      <WppLink
        href={`https://wa.me/${wppNumber}?text=${message}`}
        target="_blank"
        rel="noreferrer"
        className="d-flex gap-2 justify-content-center fs-5 py-2 text-success text-decoration-none"
      >
        <BsWhatsapp className="fs-5 text-success align-self-center" />
        <span>Falar com um atendente</span>
      </WppLink>
    </>
  );
};

export default ServiceByWpp;
