import Button from "components/button";
import { useState } from "react";

import VerificationInput from "react-verification-input";
import Swal from "sweetalert2";
import { BsFillCheckCircleFill } from "react-icons/bs";

import { api } from "services/api";
import * as C from "./styles";
import { PasswordRecoveryStepOneResult } from "types";
import {
  autoCloseValidationMessage,
  passwordRecoveryStep4,
  passwordRecoverySteps2and3,
} from "utils/popUp";
import ServiceByWpp from "../serviceByWpp";

interface CodeValidationFormProps {
  emailOrCpf: string;
  inputOption: any;
  resultStepOne: PasswordRecoveryStepOneResult;
}

const CodeValidationForm = ({
  emailOrCpf,
  inputOption,
  resultStepOne,
}: CodeValidationFormProps) => {
  const [code, setCode] = useState<string>("");
  const [wasResent, setWasResent] = useState<boolean>(false);
  const handleOnChange = (res: string) => {
    setCode(res);
  };

  const validateCode = async () => {
    Swal.resetValidationMessage();

    if (code.length === 8) {
      Swal.showLoading();
      try {
        await api.post("/Auth/recoverycodevalidate", {
          code,
          emailOrCpf,
        });
        Swal.hideLoading();

        // Step 4 - New Password
        passwordRecoveryStep4(code, emailOrCpf);
      } catch (error) {
        Swal.hideLoading();
        autoCloseValidationMessage(
          "Código inválido. Verifique se o código foi digitado de modo correto e tente novamente.",
          7000
        );
      }
    } else {
      autoCloseValidationMessage(
        "Por favor, preencha os campos de modo correto.",
        6000
      );
    }
  };

  const resendCode = async () => {
    Swal.showLoading();

    try {
      await api.post(`/Auth/recoveryBy${inputOption}`, resultStepOne.request);
      Swal.hideLoading();
      setWasResent(true);
      setTimeout(() => {
        setWasResent(false);
      }, 4000);
    } catch (error) {
      Swal.hideLoading();
      autoCloseValidationMessage("Ocorreu um erro, tente mais tarde", 4000);
    }
  };

  const backToStep2 = () => {
    passwordRecoverySteps2and3(emailOrCpf, <ServiceByWpp />, resultStepOne);
  };

  return (
    <C.Container>
      <p>
        {`Enviamos um código de verificação para o ${
          inputOption === "Email"
            ? `e-mail ${resultStepOne.response.email}.`
            : `telefone ${resultStepOne.response.phone}.`
        }`}
      </p>

      <div className="d-flex justify-content-between align-items-end mb-1">
        <span className="d-inline-block text-start">Insira o código:</span>
        <div className="d-flex flex-column">
          <button
            className="btn btn-link pe-0 py-0 text-end"
            onClick={resendCode}
          >
            <small>Reenviar código</small>
          </button>
          <button
            className="btn btn-link pe-0 py-0 text-end"
            onClick={backToStep2}
          >
            <small>Tentar de outro jeito</small>
          </button>
        </div>
      </div>
      <VerificationInput
        onChange={handleOnChange}
        length={8}
        placeholder="-"
        autoFocus
        removeDefaultStyles
        classNames={{
          container: "gap-1 gap-md-3 mb-4",
          character:
            "character rounded-1 text-info fw-bolder text-uppercase fs-2",
          characterInactive: "bg-secondary opacity-50 border-dark text-muted",
          characterSelected: "border-info border-3",
        }}
      />

      <Button
        type="button"
        blueSchema
        className="btn-lg w-100 mt-2"
        onClick={validateCode}
      >
        Validar
      </Button>

      {wasResent && (
        <C.SuccesMessage className="swal2-validation-message animate__animated animate__fadeInDown">
          <BsFillCheckCircleFill className="success-icon text-success" />
          Código reenviado!
        </C.SuccesMessage>
      )}
    </C.Container>
  );
};

export default CodeValidationForm;
