import { Link } from "react-router-dom";

import logo from "assets/logo.svg";

const LogoHeader = () => {
  return (
    <Link to={"/login"}>
      <img
        src={logo}
        alt="Logo Conectadoc"
        width={300}
        className="img-fluid mb-5"
      />
    </Link>
  );
};

export default LogoHeader;
