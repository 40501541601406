import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

  :root {
    --dark-blue: #25396f;
    --blue: #435ebe;
    --gray: #607080;
    --light-blue: #f2f7ff;
    --font-nunito: Nunito, var(--bs-font-sans-serif);
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
  font-family: var(--font-nunito);
  color: var(--gray);
  background-color: var(--light-blue);
}
  
  input, select {
    outline: none;
  }

  button {
    cursor: pointer;
  }
  
`;

export default GlobalStyle;
