import "animate.css";

import LogoHeader from "components/logoHeader";
import LoginForm from "./components/loginForm";
import PageContainer from "components/pageContainer";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useAuth } from "providers/auth";

const Login = () => {
  const { setUserType } = useAuth();
  const location = useLocation();

  useEffect(() => {
    setUserType(location.pathname.slice(1));
  }, []);

  return (
    <PageContainer>
      <LogoHeader />
      <LoginForm />
    </PageContainer>
  );
};

export default Login;
