import Button from "components/button";
import { useAuth } from "providers/auth";

const LogoutButton = () => {
  const { isLogged } = useAuth();

  const openLogoutPopUp = () => {
    let childwindow: Window | null = window.open(
      "http://localhost:3000/logout", // TODO: adicionar URL definitiva.
      "Popup",
      "width=500,height=630,left=400,top=50"
    );

    window.addEventListener(
      "message",
      (event) => {
        if (event.data === "openedReady") {
          childwindow?.postMessage(
            { message: isLogged, value: "logoutMsg" },
            "*"
          );
        }
      },
      false
    );
  };

  const logout = () => {
    localStorage.removeItem("@Conectadoc:token");
    localStorage.removeItem("@Conectadoc:user");

    openLogoutPopUp();
  };

  return (
    <Button type="button" onClick={logout}>
      <strong>Sair</strong>
    </Button>
  );
};

export default LogoutButton;
