import { useState } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import { IconType } from "react-icons";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";

import * as C from "./styles";

interface InputProps {
  register: UseFormRegisterReturn;
  icon?: IconType;
  error?: string;
  type?: string;
  label?: string;
  placeholder: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  autoFocus?: boolean;
  isInputPassword?: boolean;
  isPasswordRegistration?: boolean;
  disabled?: boolean;
}

const Input = ({
  register,
  label,
  icon: Icon = undefined,
  error,
  isInputPassword,
  isPasswordRegistration,
  disabled,
  ...rest
}: InputProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(() =>
    isInputPassword ? false : true
  );

  const togglePasswordVisiblity = () => {
    if (!disabled) {
      setShowPassword(!showPassword);
    }
  };

  return (
    <C.Container
      className="mb-3"
      isPasswordRegistration={isPasswordRegistration}
    >
      <C.InputLabel hasLabel={!!label} className="text-start d-block mb-1">
        {label}
      </C.InputLabel>
      <C.InputContainer
        className={`position-relative ${
          !!error && "animate__animated animate__headShake"
        }`}
        hasIcon={!!Icon}
        hasError={!!error}
        disabled={disabled}
      >
        <input
          {...register}
          {...rest}
          disabled={disabled}
          type={showPassword ? "text" : "password"}
          className="m-0 form-control form-control-lg"
        />
        <C.InputIcon hasError={!!error} className="form-control-icon-start">
          {Icon && <Icon fontSize={20} className="align-top" />}
        </C.InputIcon>
        {isInputPassword && (
          <C.InputIcon
            className="form-control-icon-end fs-4"
            isInputPassword
            onClick={togglePasswordVisiblity}
            disabled={disabled}
          >
            {showPassword ? <BsFillEyeSlashFill /> : <BsFillEyeFill />}
          </C.InputIcon>
        )}
      </C.InputContainer>
      <div
        className={`input-error 
        ${error ? "visible" : "invisible"} 
        text-start lh-sm`}
      >
        {!!error && <small>{error}</small>}
      </div>
    </C.Container>
  );
};

export default Input;
