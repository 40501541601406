import { ReactNode } from "react";
import * as C from "./styles";

interface ButtonProps {
  children: ReactNode;
  type?: "submit" | "button" | "reset";
  onClick?: () => void;
  blueSchema?: boolean;
  className?: string;
  disabled?: boolean;
}

const Button = ({ children, blueSchema, className, ...rest }: ButtonProps) => {
  return (
    <C.Container
      {...rest}
      className={`btn ${className}`}
      blueSchema={blueSchema}
    >
      {children}
    </C.Container>
  );
};

export default Button;
