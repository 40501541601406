import { createContext, ReactNode, useContext, useState } from "react";
import { api } from "services/api";
import { UserLoginData } from "types";
import { toast } from "utils/toast";

interface AuthProviderProps {
  children: ReactNode;
}

interface AuthProviderData {
  isLogged: boolean;
  setIsLogged: React.Dispatch<React.SetStateAction<boolean>>;
  userType: string;
  setUserType: React.Dispatch<React.SetStateAction<string>>;
  login: (
    data: UserLoginData,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setIsLoginSuccess: React.Dispatch<React.SetStateAction<boolean>>
  ) => Promise<void>;
}

export const AuthContext = createContext<AuthProviderData>(
  {} as AuthProviderData
);

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [isLogged, setIsLogged] = useState(
    localStorage.getItem("@Conectadoc-auth:login") ? true : false
  );
  const [userType, setUserType] = useState("");

  const login = async (
    data: UserLoginData,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setIsLoginSuccess: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setIsLoading(true);

    const { email, password } = data;
    const body = { email, password };

    try {
      let response;
      if (userType === "doctor") {
        response = await api.post("/SpecialistDoctor/login", body);
      } else if (userType === "patient") {
        response = await api.post("/Patient/login", body);
      } else{
        response = await api.post("/Patient/loginSecretary", body);
      }
      setIsLoading(false);

      setIsLoginSuccess(true);

      localStorage.setItem("@Conectadoc-auth:login", "loggedIn");

      await toast.fire({
        icon: "success",
        title: "Login realizado com sucesso!",
        timer: 2500,
      });

      window.opener.postMessage(
        { message: JSON.stringify(response), value: "loginMsg" },
        "*"
      );

      window.close();
    } catch (error: any) {
      setIsLoading(false);
      let message =
        error.response && error.response.status === 404
          ? "Usuário ou senha inválidos. Verifique se seus dados estão corretos e tente novamente."
          : "Ocorreu um erro. Por favor, tente mais tarde";

      toast.fire({
        icon: "error",
        title: message,
      });
    }
  };

  return (
    <AuthContext.Provider
      value={{ login, isLogged, setIsLogged, userType, setUserType }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
